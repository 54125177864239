import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import farmsConfig from 'config/constants/farms'
import { getMasterChefAddress } from 'utils/addressHelpers'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

export const fetchFarmUserAllowances = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = farm.isTokenOnly ? farm.tokenAddresses[CHAIN_ID] : farm.lpAddresses[CHAIN_ID]
    return { address: lpContractAddress, name: 'allowance', params: [account, masterChefAdress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = farm.isTokenOnly ? farm.tokenAddresses[CHAIN_ID] : farm.lpAddresses[CHAIN_ID]
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmUserStakedBalances = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    return {
      address: masterChefAdress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(masterchefABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFarmUserNFTStaked = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    return {
      address: masterChefAdress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawNFTDetails = await multicall(masterchefABI, calls)
  const parsedNFTDetails = rawNFTDetails.map((resp) => {
    return parseInt(resp.stakedNFTId, 16)
  })
  return parsedNFTDetails
}

export const fetchFarmUserEarnings = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    return {
      address: masterChefAdress,
      name: 'pendingPYDEX',
      params: [farm.pid, account],
    }
  })

  let parsedEarnings = []
  try {
    const rawEarnings = await multicall(masterchefABI, calls)
    parsedEarnings = rawEarnings.map((earnings) => {
      console.log('earning', earnings.nftReward._hex, earnings, {
        nftReward: new BigNumber(earnings.nftReward._hex).toJSON(),
        normalReward: new BigNumber(earnings.normalReward._hex).toJSON(),
        totalRewards: new BigNumber(earnings.totalRewards._hex).toJSON(),
      })

      return {
        nftReward: new BigNumber(earnings.nftReward._hex).toJSON(),
        normalReward: new BigNumber(earnings.normalReward._hex).toJSON(),
        totalRewards: new BigNumber(earnings.totalRewards._hex).toJSON(),
      }
    })
  } catch (err) {
    parsedEarnings = farmsConfig.map(() => {
      return {
        nftReward: new BigNumber(0).toJSON(),
        normalReward: new BigNumber(0).toJSON(),
        totalRewards: new BigNumber(0).toJSON(),
      }
    })
  }

  return parsedEarnings
}
