import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from '@apollo/client'

export const client = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/shahzeb8285/privacynft',
  cache: new InMemoryCache(),
})

export const GET_MY_NFTS = (address) => {
  return `

    {
        nfts(where:{owner:"${address}"}) {
          id
          owner
          type
          isStaked
        }
      }
      
    `
}

export const getMyNFTs = async (address) => {
  console.log('getMyNFTs', address)
  return client.query({
    query: gql`
      ${GET_MY_NFTS(address)}
    `,
  })
}
