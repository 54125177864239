import addresses from 'config/constants/contracts'

const chainId = process.env.REACT_APP_CHAIN_ID

export const getCakeAddress = () => {
  return addresses.cake[chainId]
}

export const getPRV2Address = () => {
  return addresses.prv2[chainId]
}

export const getPRVGAddress = () => {
  return addresses.prvg[chainId]
}
export const getMasterChefAddress = () => {
  return addresses.masterChef[chainId]
}

export const getDollarToDollarPresale = (isPRV2) => {
  return isPRV2 ? addresses.dollarToDollarPRV2[chainId] : addresses.dollarToDollarPRVG[chainId]
}

export const getPeggedPresale = (isPRV2) => {
  return isPRV2 ? addresses.peggedPRV2[chainId] : addresses.peggedPRVG[chainId]
}
export const getMulticallAddress = () => {
  return addresses.mulltiCall[chainId]
}
export const getWbnbAddress = () => {
  return addresses.wbnb[chainId]
}
export const getLotteryAddress = () => {
  return addresses.lottery[chainId]
}
export const getLotteryTicketAddress = () => {
  return addresses.lotteryNFT[chainId]
}

export const getReferralAddress = () => {
  return addresses.referral[chainId]
}

export const getNFTAddress = () => {
  return addresses.prvNFT[chainId]
}
