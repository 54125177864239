import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'LanguageIcon',
    href: '/',
  },

  {
    label: 'PYDEX Swap',
    icon: 'HomeIcon',
    initialOpenState: false,
    items: [
      {
        label: 'Dollar to Dollar',
        href: '/dollarToDollar',
      },
      {
        label: 'Pegged Price',
        href: '/peggedPresale',
      },
    ],
  },
  {
    initialOpenState: false,

    label: 'Exchange',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://amm.privacyswap.finance/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://amm.privacyswap.finance/#/pool',
      },
    ],
  },

  {
    label: 'Farm',
    icon: 'FarmIcon',
    href: '/farms',
  },

  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },

  {
    label: 'Partner Pools (TBA)',
    icon: 'HandshakeIcon',
    href: '#',
  },

  {
    label: 'PrivacyCard (TBA)',
    icon: 'RoadmapIcon',
    items: [
      {
        label: 'Get PrivacyCard',
        href: '',
      },
      {
        label: 'PrivacyCard Staking (TBA)',
        href: '',
      },
      {
        label: 'Partnerships',
        href: 'https://cards.privacyswap.finance/partnership',
      },
    ],
    // href: '#',
  },

  {
    label: 'Privacy LaunchPad (TBA)',
    icon: 'RoadmapIcon',
    href: '#',
  },

  {
    label: 'Referrals',
    icon: 'NftIcon',
    href: '/referral',
  },

  {
    label: 'Analytics',
    icon: 'VaultsIcon',
    href: 'https://analytics.privacyswap.finance',
  },

  {
    label: 'Price Charts',
    icon: 'TradeUpdatedIcon',
    items: [
      {
        label: 'DexScreener',
        href: 'https://dexscreener.com/bsc/0xebedf67e37f0c7f2b9018edd04cb3460d1b2c16b'
      },
      {
        label: 'PooCoin',
        href: 'https://poocoin.app/tokens/0x5eba2e191e1650043f50e9454a87f1998317b1a2',
      },
      {
        label: 'DexGuru',
        href: 'https://dex.guru/token/0x5eba2e191e1650043f50e9454a87f1998317b1a2-bsc',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/privacyswap/',
      },
      {
        label: 'Gitbook',
        href: 'https://docs.privacyswap.finance/',
      },
      {
        label: 'Blog',
        href: 'https://privacyswap.medium.com/',
      },
    ],
  },
]

export default config
