import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'PYDEX',
    lpAddresses: {
      97: contracts.cake[97],
      56: '0xEBedF67E37F0C7F2b9018EdD04cb3460d1b2C16B',
    },
    tokenSymbol: 'PYDEX ',
    isTokenOnly: true,
    tokenAddresses: {
      97: contracts.cake[97],
      56: '0x5eba2e191e1650043f50e9454a87f1998317b1a2',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 1,
    risk: 5,
    lpSymbol: 'PYDEX-BUSD LP',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0xEBedF67E37F0C7F2b9018EdD04cb3460d1b2C16B',
    },
    tokenSymbol: 'PYDEX',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x5eba2e191e1650043f50e9454a87f1998317b1a2',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 2,
    risk: 5,
    lpSymbol: 'PYDEX-BNB LP',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0xD87414Da46AE8C95B1dF3082Ef6DD2433c99D959',
    },
    tokenSymbol: 'PYDEX',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x5EBA2E191e1650043f50E9454a87f1998317b1a2',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 3,
    risk: 5,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0x8F081cC1D1b3e3031c39Cf794A1B92b00fC1bf9A',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: contracts.wbnb[56],
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 4,
    risk: 5,
    lpSymbol: 'BTCB-BNB LP',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0xD8c432DE994C8AdC97A3c41DAF867cE31c78Bd9d',
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: contracts.btcb[56],
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 5,
    risk: 5,
    lpSymbol: 'USDT-BUSD LP',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0x41e49c121f1C1227E311FE5F52d04B69aec50E9d',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x55d398326f99059fF775485246999027B3197955',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  // {
  //   pid: 0,
  //   risk: 0,
  //   lpSymbol: 'PRV-BNB LP',
  //   lpAddresses: {
  //     97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
  //     56: '0xCd18e1151a4831e5EbCEcC611369123012674253',
  //   },
  //   tokenSymbol: 'PRV',
  //   tokenAddresses: {
  //     97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
  //     56: '0x5EBA2E191e1650043f50E9454a87f1998317b1a2',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
]

export default farms
