export default {
  cake: {
    56: '0x5eba2e191e1650043f50e9454a87f1998317b1a2',
    97: '0x01Fc629A1eDc882A17014bAC69FD73517Cb200B9',
  },
  masterChef: {
    56: '0x1b9eDCe5512A1c6d917DbaFe1e35E983A9C6ceB9',
    97: '0x5396b64CBC4441f30be8fB83Ac7e9AcFA2274a88',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xA73D61379627dB94f968C7bb4BD7fF5c07723Ec2',
  },
  lottery: {
    56: '0x8604B06459c3e7200355DcE354921E3012cDD43F',
    97: '0x78f97C5dF7FC51E68DeA698DB7EF1DA96681f880',
  },
  btcb: {
    56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    97: '0x78f97C5dF7FC51E68DeA698DB7EF1DA96681f880',
  },
  lotteryNFT: {
    56: '0x8604B06459c3e7200355DcE354921E3012cDD43F',
    97: '0x8604B06459c3e7200355DcE354921E3012cDD43F',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x4998c1031e1c9cd49fe31f6786a0e6090fda95ea',
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    97: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  },
  referral: {
    56: '0x2C8013B5F63a59e5a2c61D894058115566b7217B',
    97: '0x62605b8b6217DAbEb576A86fCBC6a6a74A78e4A7',
  },

  nft: {
    97: '0x6A37430A5DDb92EB2E47F847F0b1908072665aB8',
    56: '0x0f655fBC4C7B72938d7a2B62c7d22c8f00aD8E15',
  },

  prvNFT: {
    97: '0x6A37430A5DDb92EB2E47F847F0b1908072665aB8',
    56: '0x0f655fBC4C7B72938d7a2B62c7d22c8f00aD8E15',
  },

  prv2: {
    56: '0xeE10936E1F6B49945B17EFeB106cc6992fe5ae47',
    97: '0x472dA1E8E052C07D289a0C7290EBB584bC699E9B',
  },
  prvg: {
    56: '0x774ad968f8f3801739bcbc970f833c664d28511d',
    97: '0x472dA1E8E052C07D289a0C7290EBB584bC699E9B',
  },

  dollarToDollarPRV2: {
    56: '0x5C3Ad6684F4e00E3065e9949A572C9A37A55E045',
    97: '0xbB7f6301B35D0A4a6BD45A54A5AD162e9160834B',
  },
  dollarToDollarPRVG: {
    56: '0xB5F24Eb2231cCbA72EED0AeE3021f54264d53DA3',
    97: '0xbB7f6301B35D0A4a6BD45A54A5AD162e9160834B',
  },

  peggedPRVG: {
    56: '0x411863557c449830AA3dEbCb82c64d7200F6E39f',
    97: '0xaAfD883938955EA3aBAb24EBBea92CF1D9972e92',
  },
  peggedPRV2: {
    56: '0xA56045221d373fDC64E3c86645C78Fb8F082b0b1',
    97: '0xaAfD883938955EA3aBAb24EBBea92CF1D9972e92',
  },
}
