import React from 'react'
import styled from 'styled-components'

import { BsTelegram ,BsFacebook} from 'react-icons/bs'
import { AiFillTwitterCircle,AiFillMediumCircle,AiFillYoutube,AiFillRedditCircle } from 'react-icons/ai'

import { Text, Flex} from '@pancakeswap-libs/uikit'
import { H } from './Partner'

const StyledFooter = styled.div`
  background-color: #001930;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 2rem 2rem 2rem;
  margin: 3rem 0 0 0;
  @media only screen and (max-width: 585px) {
    flex-direction: column;
    padding: 2rem 1rem;
  }
`
const Img = styled.div`
  width: 9rem;
  height: 11rem;
  margin: 2rem 0 0 4.5rem;
  @media only screen and (max-width: 585px) {
    margin: 0rem;
  }
`
const SImg = styled.a`
  width: 2rem;
  height: 1.8rem;
  margin:5px;
  cursor: pointer;
  @media only screen and (max-width: 585px) {
    margin: 0rem;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  @media only screen and (max-width: 768px) {
    margin: 2rem 0 0 0;
  }
`
const Hh = styled(H)`
  margin: 1rem 0;
  text-align: left;
`

const T = styled.a`
  text-align: left !important;
  color:#fff;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  margin: 0.25rem 0;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
`
const Row = styled.div``
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  // place-items: center;
  width: 70%;
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
    width: 100%;
    place-items: center;
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      <Img>
        <img src="/biglogo.png" alt="" style={{ height: '120px', width: '100px' }} />
      </Img>
      <Grid>
        <Column>
          <Hh>About</Hh>
          <T href='https://docs.privacyswap.finance/' target="_blank" >Docs</T>
          {/* <T>Team</T> */}
          <T href="https://blog.privacyswap.finance/" target="_blank">Blog</T>
          <T href="https://github.com/privacyswap/" target="_blank">Github</T>
          {/* <T>Bug Bounty</T> */}
        </Column>
        
        <Column>
          <Hh>Service</Hh>
          <T href='/referral'>Referral program</T>
          <T href="https://bscscan.com/address/0x5eba2e191e1650043f50e9454a87f1998317b1a2" target="_blank">PYDEX Token</T>
          <T href="https://docs.google.com/forms/d/e/1FAIpQLSf3V3RJSkzWOQlry_p0N-5Bf4j7zaPZWCw-aTf3IHjHEMtymg/viewform" target="_blank">Apply to Launch</T>
          {/* <T>$10M Program</T> */}
        </Column>
        <Column>
          <Hh>Community</Hh>
         
          <Row>
            <Flex style={{ width: '6rem' }} justifyContent="space-between">
              <SImg href="https://t.me/PrivacySwap" target="_blank">
              <BsTelegram size={30}  color="#808291"/>

                {/* <img src="/telegram.png" alt="" style={{ height: '100%', width: '100%' }} /> */}
              </SImg>
              <SImg href="https://twitter.com/PrivacySwap" target="_blank">
                <AiFillTwitterCircle size={30}  color="#808291"/>
              </SImg>
              <SImg href="https://privacyswap.medium.com/" target="_blank">
                <AiFillMediumCircle size={30}  color="#808291"/>

              </SImg>
            </Flex>
            <Flex style={{ width: '6rem', margin: '1rem 0 0 0' }} justifyContent="space-between">
            
              <SImg href="https://www.youtube.com/c/PrivacySwap_Official" target="_blank">
                <AiFillYoutube size={30}  color="#808291"/>
              </SImg>
              <SImg href="https://www.facebook.com/privacyswap.finance" target="_blank">
                <BsFacebook size={30}  color="#808291"/>
              </SImg>
              <SImg href="https://www.reddit.com/r/privacyswap/" target="_blank">
                {/* <img src="/re.png" alt="" style={{ height: '100%', width: '100%' }} /> */}
                <AiFillRedditCircle size={30}  color="#808291"/>
              </SImg>
            </Flex>
           
          </Row>
        </Column>
      </Grid>
    </StyledFooter>
  )
}

export default Footer
