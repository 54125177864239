import React from 'react'
import styled from 'styled-components'
import { Heading, Text, BaseLayout } from '@pancakeswap-libs/uikit'

const StyledPartner = styled.div`
  width: 100%;
`
const Column = styled.div``
const Img = styled.div`
  height: 3rem;
  width: 9rem;
  margin: 2rem 0;
  @media only screen and (max-width: 768px) {
    height: 2rem;
    width: 6rem;
  }
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  place-items: center;
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  }
`
export const H = styled(Heading)`
  text-align: center;
  font-size: 2rem;
  margin: 3rem 0 2rem 0;
  @media only screen and (max-width: 768px) {
    font-size: 1.3rem;
  }
`

const Partner = () => {
  return (
    <StyledPartner>
      <H>PrivacySwap Partners, Audits, and Features</H>
      <Grid>
        <Column>
          <Img>
            <img src="/brands/AMBCryptoLogo.png" alt=""  />
          </Img>
          <Img>
            <img src="/brands/RugDocLogo.png" alt=""  />
          </Img>
          <Img>
            <img src="/brands/BSCNewsLogo.png" alt=""  />
          </Img>
        </Column>
        <Column>
          <Img>
            <img src="/brands/CryptoPotatoLogo.png" alt=""/>
          </Img>
          <Img>
            <img src="/brands/PaladinLogo.png" alt="" />
          </Img>
          <Img>
            <img
              src="/brands/CoinGeckoLogo.png"
              alt=""
              // style={{ height: '100%', width: '100%', borderRadius: '0.5rem' }}
            />
          </Img>
        </Column>
        <Column>
          
            
        <Img>
            <img src="/brands/DAppRadarLogo.png" alt="" />
          </Img>
          <Img>
            <img src="/brands/YahooFinance.png" alt=""/>
          </Img>
          
          <Img>
            <img
              src="/brands/BusinessInsiderLogo.png"
              alt=""
              // style={{ height: '100%', width: '100%', borderRadius: '0.5rem' }}
            />
          </Img>
        </Column>
        <Column>

        <Img>
            <img src="/brands/CoinMarketCapLogo.png" alt="" />
          </Img>
         <Img/>
          <Img>
            <img src="/brands/CoinTelegraph.png" alt="" />
          </Img>
        
        </Column>
        {/* <Column>
          <Img>
            <img src="/e.png" alt="" style={{ height: '100%', width: '100%', borderRadius: '0.5rem' }} />
          </Img>
          <Img>
            <img src="/e.png" alt="" style={{ height: '100%', width: '100%', borderRadius: '0.5rem' }} />
          </Img>
          <Img>
            <img src="/e.png" alt="" style={{ height: '100%', width: '100%' , borderRadius: '0.5rem'}} />
          </Img>
        </Column>
        <Column>
          <Img>
            <img src="/f.png" alt="" style={{ height: '100%', width: '100%', borderRadius: '0.5rem' }} />
          </Img>
          <Img>
            <img src="/f.png" alt="" style={{ height: '100%', width: '100%', borderRadius: '0.5rem' }} />
          </Img>
          <Img>
            <img src="/f.png" alt="" style={{ height: '100%', width: '100%', borderRadius: '0.5rem' }} />
          </Img>
        </Column> */}
      </Grid>
    </StyledPartner>
  )
}

export default Partner
